// <------------------>
//  Common
// <------------------>
export const TIMEOUT_30_MINUTES_MS = 1800000;

// <------------------>
//  Session
// <------------------>
export const SESSION_KEY_PREFIX = "RAC_CMP_";
export const CONTEXT_POLICY_NUMBER_SESSION_KEY = `${SESSION_KEY_PREFIX}ContextPolicyNumber`;
export const CONTEXT_FLOW_STATE_SESSION_KEY = `${SESSION_KEY_PREFIX}FlowState`;
export const CONFIRMATION_SESSION_STATE_KEY = `${SESSION_KEY_PREFIX}Confirmation`;
export const PRE_FORM_SESSION_STATE_KEY = "PreForm";
export const RENEWAL_TIME_SESSION_STATE_KEY = "RenewalTime";

// <------------------>
//  Spark
// <------------------>
export const MOTOR_ENDORSEMENT_URL = process.env.REACT_APP_SPARK_MOTOR_ENDORSEMENT_URL ?? "";
export const CARAVAN_ENDORSEMENT_URL = process.env.REACT_APP_SPARK_CARAVAN_ENDORSEMENT_URL ?? "";

// <------------------>
//  Service
// <------------------>
export const BFF_API_BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "";
export const LOOKUP_SERVICE_API_BASE_URL = process.env.REACT_APP_LOOKUP_SERVICE_API_BASE_URL ?? "";
export const MFA_OTP_SERVICE_API_BASE_URL = process.env.REACT_APP_MFA_OTP_SERVICE_API_BASE_URL ?? "";

// <------------------>
//  MyRAC AADB2C Auth
// <------------------>
export const ADB2C_DOMAIN = process.env.REACT_APP_ADB2C_DOMAIN ?? "";
export const ADB2C_TENANT = process.env.REACT_APP_ADB2C_TENANT ?? "";
export const ADB2C_CLIENT_ID = process.env.REACT_APP_ADB2C_CLIENT_ID ?? "";

// <------------------>
//  Atoms
// <------------------>
export const SESSION_KEY_ATOM_SESSION_ID = `${SESSION_KEY_PREFIX}ATOM_SESSION_ID`;
export const SESSION_KEY_ATOM_POLICY_NUMBER = `${SESSION_KEY_PREFIX}ATOM_POLICY_NUMBER`;
export const SESSION_KEY_ATOM_GTM_POLICY_TYPE = `${SESSION_KEY_PREFIX}ATOM_GTM_POLICY_TYPE`;
export const SESSION_KEY_ATOM_SHOW_WESTPAC_RETRY_DIALOG = `${SESSION_KEY_PREFIX}ATOM_SHOW_WESTPAC_RETRY_DIALOG`;

// <------------------>
//  Page title, sidebar title, steppers & breadcrumbs
// <------------------>
export const HOW_YOU_PAY_FORM_HEADER = "Update payment details";
export const HOW_YOU_PAY_TITLE = "Update how you pay";
export const HOW_YOU_PAY_SIDEBAR_PROGRESS = "Payment details";

// <------------------>
//  Enums
// <------------------>
export const enum PaymentFrequency {
  Annual = "Annually",
  SemiAnnual = "Half-yearly",
  Monthly = "Monthly",
}
